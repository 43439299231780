import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/nft',
    name: 'nft',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NFT.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/contact.vue'),
  },
  {
    path: '/market',
    component: () => import('@/views/marketList/marketList.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/market/marketDetails',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/MarketDetails/MarketDetails.vue'),
  },
  {
    path: '/market/marketPayList',
    component: () => import('@/views/marketPayList/marketPayList.vue'),
  },
  {
    path: '/market/marketPay',
    component: () => import('@/views/marketPay/marketPay.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

export default router
