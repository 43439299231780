<template>
  <div id="app">
    <navigation-bar></navigation-bar>
    <div class="bg" v-if="$route.path === '/home' || $route.path === '/nft' || $route.path === '/contact'">
      <!-- <div class="bx"> -->
        <div class="bx-content">
          <img src="@/assets/Group 11@2x.png" alt="" />
          <div>數字藏品解決方案</div>
        </div>
      <!-- </div> -->
    </div>
    <router-view/>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import NavigationBar from '@/components/navigation-bar/navigation-bar.vue'
import FooterBar from '@/components/footer/footer.vue'
export default {
  components: {
    NavigationBar,
    FooterBar
  }
}
</script>
<style lang="less" scoped>
.bx-content {
    padding-top: 4.06rem;
    padding-left: 4.28rem;
    img {
      width: 2.66rem;
      height: .79rem;
      margin-bottom: .18rem;
    }
    div {
      font-size: .6rem;
      font-weight: 400;
      color: #ffffff;
      line-height: .84rem;
    }
  }
</style>
