import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, getToken, remToken } from '../store/token'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: getToken() || {},
    loginShow: false, // 控制登录弹框
    isShowLoading: false
  },
  getters: {},
  mutations: {
    setTokenInfo(state, newSetToken) {
      state.userInfo = newSetToken
      setToken(newSetToken)
    },
    remTokenInfo(state) {
      state.userInfo = {}
      remToken()
    },
    setLoginShow(state, newLoginShow) {
      state.loginShow = newLoginShow
    },
    SET_SHOWLOADING(state, data) {
      state.isShowLoading = data;
    }
  },
  actions: {},
  modules: {},
})
