import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/css/index.css'
import './router/permission.js'
import formatTime from './common/fileters'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import marketList from './views/marketList/marketList.less'
import marketDetails from './views/MarketDetails/MarketDetails.less'
import marketPayList from './views/marketPayList/marketPayList.less'
import marketPay from './views/marketPay/marketPay.less'
import loginbox from './components/login/loginbox.less'
import nftmarketbox from './components/NftMarket-box/nftmarketbox.less'
Vue.prototype.formatTime = formatTime
zhLocale.el.pagination = {
  goto: '前往',
  pagesize: '條/頁',
  total: `共 {total} 條`,
  pageClassifier: '頁',
};
Vue.use(ElementUI, {
  locale: zhLocale,
});
Vue.use(loginbox)
Vue.use(marketList)
Vue.use(marketDetails)
Vue.use(marketPayList)
Vue.use(marketPay)
Vue.use(nftmarketbox)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		setRemPc();
        window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
	},
}).$mount('#app')

//rem计算
function setRemPc() {
  var width = 100/1920;// 表示1454的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  if(bodyWidth >= 1280 && bodyWidth <= 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem = bodyWidth * width;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  } else {
    document.getElementsByTagName('html')[0].style.fontSize = 100 + 'px'; // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}
