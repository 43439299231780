<template>
  <div class="market_content">
    <div class="content_box">
      <div class="head_right_login">
        <div v-if="userInfo.token" style="display: flex; align-items: center;">
          <img style="border-radius: 50%;" :src="userInfo.imagePath ? userInfo.imagePath : headImg" alt="">
          <el-dropdown trigger="click" style="cursor: pointer;">
  <span class="el-dropdown-link my_message common-text">個人中心<i class="el-icon-arrow-down el-icon--right"></i>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item style="height: .5rem; line-height: .5rem;"><router-link to="/market/marketPayList">訂單列表</router-link></el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
        <div class="hover-text common-text" @click="dialogVisible = true">Log out</div>
        </div>
        <div class="hover-text common-text" v-else @click="setLoginShow(true)">Login</div>
      </div>
    </div>
    <el-dialog
  title="退出登錄"
  :visible.sync="dialogVisible"
  :append-to-body="true"
  width="30%">
  <span>您確定要退出登錄嗎？</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="okBtn">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { headImg } from '@/common/common.js'
export default {
  name: 'marketbox',
  data() {
    return {
      dialogVisible: false,
      headImg: localStorage.getItem('headImg'),
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    if(!localStorage.getItem('headImg')) {
          this.getHeadImg()
        }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'remTokenInfo']),
    // 讓用戶隨機使用默認頭像
    getHeadImg() {
        if(!localStorage.getItem('headImg')) {
          let a = Math.floor(Math.random()*10)
          let img = headImg[a].imgUrl
          localStorage.setItem('headImg', img)
          this.headImg = img
        }
      },
    okBtn() {
      this.remTokenInfo()
      this.dialogVisible = false
      this.$message.success('退出登錄成功')
      this.$router.push('/market')
    },
  },
}
</script>

<style></style>
