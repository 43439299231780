<template>
  <div class="content">
    <div class="bx bx-padding">
      <div class="middle-title">
        <img src="@/assets/Group 32@2x.png" alt="" />
        <div class="title">客制化NFT數字藏品系統</div>
      </div>
      <div class="middle">
        <div class="item" style="padding-left: 1.81rem;">
          <img src="@/assets/Group 13@2x.png" alt="" />
          <div class="text">
            · 在區塊鏈上建立你的數字資產<br />
            · 智能合約驗證數字資產<br />
            · 易於使用的數字藏品系統
          </div>
        </div>
        <div class="item" style="padding-left: 1.58rem">
          <img style="width: 3.77rem; height: 2.32rem;" src="@/assets/Group 2@2x.png" alt="" />
          <div class="text">
            支持多種支付方式<br>
            只需手機號碼驗證即可購<br>
            7天x 24小時運營，30秒快速交易
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
}
</script>

<style lang="less" scoped>
.content {
  .bx {
    .middle-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 3.49rem;
        height: 1.85rem;
        margin-bottom: .26rem;
      }
      .title {
        font-size: .7rem;
        font-weight: 600;
        color: #4f4f4f;
        margin-bottom: .94rem;
      }
    }
    .middle {
      height: 8.87rem;
      background: #ffffff;
      box-shadow: 0 .12rem .43rem 0px rgba(0, 0, 0, 0.3);
      border-radius: .27rem;
      box-sizing: border-box;
      padding: 1.3rem 0 1.76rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        align-items: center;
        img {
          width: 3.63rem;
          height: 1.31rem;
          margin-right: .53rem;
        }
        .text {
          font-size: .3rem;
          font-weight: 400;
          color: #4f4f4f;
          line-height: .6rem;
        }
      }
    }
  }
}
</style>
