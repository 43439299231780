<template>
  <div class="content">
    <ul class="head">
      <div class="left">
        <li>
        <img @click="$router.push('/')" style="cursor: pointer;" src="@/assets/Bitmap@2x.png" alt="" />
      </li>
      <li :style="$route.path == '/home' ? 'color: #7fdef0' : ''" @click="$router.push('/')" style="cursor: pointer;">
        Home
      </li>
      <li :style="$route.path == '/nft' ? 'color: #7fdef0' : ''" @click="$router.push('/nft')" style="cursor: pointer;">NFT</li>
      <li :style="$route.path == '/contact' ? 'color: #7fdef0' : ''" @click="$router.push('/contact')" style="cursor: pointer; margin-right: .5rem">Contact us</li>
      <li :style="$route.path == '/market' ? 'color: #7fdef0' : ''" @click="$router.push('/market')" style="cursor: pointer;">Online Shop</li>
      </div>
      <div class="right" v-if="$route.path != '/home' && $route.path != '/nft' && $route.path != '/contact'">
        <nft-market-box></nft-market-box>
      </div>
    </ul>
  </div>
</template>

<script>
import NftMarketBox from '../NftMarket-box/nftmarketbox.vue'
export default {
  components: {
    NftMarketBox
  },
  created() {
    console.log(this.$route)
  }
}
</script>

<style lang="less" scoped>
.content {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 1rem;
  background: rgba(0, 0, 0, 0.9);
  padding-left: 3.8rem;
  box-sizing: border-box;
  .head {
    height: 100%;
    display: flex;
    font-size: .18rem;
    font-weight: 400;
    color: #ffffff;
    align-items: center;
    justify-content: space-between;
    img {
      width: .85rem;
      height: .25rem;
      margin-bottom: .1rem;
    }
    li {
      // padding: 0 .3rem;
      width: 1.1rem;
      text-align: center;
    }
    li:hover {
      color: #7fdef0;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      padding-right: 1rem;
      // background-color: #fff;
    }
  }
}
</style>
