import router from "./index";
import store from "../store/index"

// 导航守卫
router.beforeEach((to, from, next) => {
  // window,scrollTo(0,0);
  const token = store.state.userInfo.token
  const url = to.path
  const whiteList = ['/market/marketDetails', '/market/marketPayList', '/market/marketPay'] 
  // 已登录放行
  if(token && whiteList.includes(url)){
      next()
      return
  }
  
  // 未登录拦截
  if(!token && whiteList.includes(url)){
    if(from.path === '/market') {
      store.commit('setLoginShow',true)
      return
    }
    next('/market')
    store.commit('setLoginShow',true)
    return
  }
  next()
})