<template>
  <div class="content">
    © Copyright 2022 wefiv - All Rights Reserved
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.content {
  text-align: center;
  line-height: 1rem;
  height: 1rem;
  width: 100%;
  background-color: #3c3c3c;
  font-size: .18rem;
  font-weight: 400;
  color: #b6b6b6;
}
</style>
